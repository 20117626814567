<template>
  <div id="home-hero" class="relative">
    <img class="logo sm:hidden" src="../../assets/images/Logo.png" alt="EESTEC Logo">
    <div class="description sm:hidden">
     {{$t('home.hero.descriptionMobile')}}
    </div>
    <a href="https://linktr.ee/eestec_lc_bucharest" class="apply-button-mobile">
      <button class="self-center justify-self-end font-montserrat mt-auto px-8 py-3 font-bold
                         rounded-3xl mb-10 "
                  :class="{'bg-white': !alternativeLook, 'bg-black': alternativeLook,
                           'text-grey': alternativeLook, 'text-dark_red': !alternativeLook}"
                  >
            {{ $t(`global.navbar.pages[5]`) }}
    </button></a>
    <div class="hidden sm:block">
      <div
        v-html="$t('home.hero.callToAction')"
        class="z-20 text-white font-robot font-bold text-8xl absolute top-1/3 left-20"
      />
      <div class="location-marker bg-white w-6 h-6 absolute z-50 rounded-full"></div>
      <div class="location-message text-white font-roboto font-bold text-6xl z-20 absolute">
        {{$t('home.hero.locationMessage')}}
      </div>
    </div>
    <div class="orientation bg-white text-darker_red text-lg font-montserrat
         z-10 font-extrabold absolute bottom-1/2 -right-9 p-3 hidden sm:block"
         @click="goToPage('/help-us')"
    >
      {{$t('home.hero.formMessage')}}
    </div>
    <ScrollDownArrow/>
  </div>
</template>

<script>
import ScrollDownArrow from "../global/Desktop/ScrollDownArrow.vue";

export default {
  components: {
    ScrollDownArrow,
  },
  methods: {
    goToPage(newPath) {
      if (this.$router.currentRoute.path !== newPath) {
        this.$router.push(newPath);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.apply-button-mobile {
  z-index: 1;
  display: none;
  @media screen and (max-width: 480px) {
    display: block;
  }
}

#home-hero {
  position: relative;
  background: url('../../assets/images/hero.png');
  height: 100vh;
  width: 100vw;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-blend-mode: multiply;
  &::after {
    content: "";
    background: $dark_red;
    position: absolute;
    inset: 0;
    opacity: 0.85;
    z-index: 0;
  }
  @media screen and (min-width: 480px) {
    background-position: 0% 75%;
    background-attachment: scroll;
  }
}
.orientation {
  min-width: 140px;
  min-height: 80px;
  transform: translateY(-50%) matrix(0, -1, 1, 0, 0, 0);
  cursor: pointer;
  text-align: center;
}
.logo {
  margin: auto;
  width: 10rem;
  z-index: 1;
}

.description {
  font-family: 'Montserrat', sans-serif;
  font-size: 1rem;
  text-align: center;
  color: white;
  margin-left: 10%;
  margin-right: 10%;
  z-index: 1;
}

.location-marker {
  left:61.5%;
  top:79%;
  &::after {
    content: "";
    width: 48px;
    height: 48px;
    position: absolute;
    top: -12px;
    left: -12px;
    border-radius: 999px;
    border-width: 1px;
    border-color: $white;
  }
}

.location-message {
  left: 65%;
  top: 70%;
  &::after {
    content: "";
    width: 60%;
    position: absolute;
    height: 6px;
    background-color: $white;
    left:50%;
    bottom: -16px;
  }
}
</style>
